.nav-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px 3%;
  align-items: center;
  background-color: transparent;
  font-size: 15px;
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 0;
  box-sizing: border-box;
  background: linear-gradient(
    228.78deg,
    #202020 20.36%,
    rgba(32, 32, 32, 0) 73.35%
  );
  font-family: "Mulish", sans-serif;
}

.right-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.call-to-action {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #563686;
  border: 0.4px solid #8a77bc;
  border-radius: 6px;
  padding: 10px 20px;
  width: 126px;
  height: 34px;
  cursor: pointer;

  color: #f5f5f5;
  font-size: 12px;
}

.call-to-action:hover {
  background: linear-gradient(94.35deg, #8a77bc -55.62%, #563686 50.98%);
}


.sign-in-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: 0.4px solid #f5f5f5;
  border-radius: 6px;
  padding: 10px 20px;
  height: 34px;
  cursor: pointer;
  z-index: 100;

  color: #f5f5f5;
  font-size: 12px;
  transition: 0.2s;
}

.sign-in-button:hover {
  background: #4C2586
}
