.splash-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/splashBackground.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  background-position: 0px -155px;
  position: relative;
  font-family: "Mulish", sans-serif;
  overflow-x: hidden;
}

.splash-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  position: absolute;
  top: 22%;
}

.slogan {
  font-weight: 700;
  font-size: 48px;
  width: 793px;
  height: auto;
  background: linear-gradient(183.35deg, #ffffff -50.82%, #563686 437.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  line-height: 60px;
  font-weight: bolder;
}

.slogan-eyecatcher {
  background: linear-gradient(92.06deg, #8a77bc 100%, #563686 -50.14%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.slogan-description {
  font-size: 18px;
  text-align: center;
  color: #bfbfbf;
  font-size: 22px;
}

.splash-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  font-size: 15px;
}

.call-to-action {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #563686;
  border: 0.4px solid #8a77bc;
  border-radius: 6px;
  padding: 10px 20px;
  width: 126px;
  height: 34px;
  cursor: pointer;

  color: #f5f5f5;
  font-size: 12px;
}

.cta-v2 {
  width: 146px;
  height: 40px;
  border-radius: 8px;
  font-size: 15px;
  margin-top: 20px;
}

.flex {
  display: flex;
}
#tab02 {
  display: none;
}
.demo-section {
  /* background gradient */
  background: linear-gradient(180deg, #000 0%, #1C1C1C 100%);
}
.demo-flex-div {
  width: 50%;
  padding: 2.5rem 5rem;
}

.demo-tabs h1 {
  font-weight: bolder;
  margin: 1rem 0;
}

.demo-tabs p {
  color: #bfbfbf;
}

.demo-tab {
  padding: .5rem;
  margin-right: 2rem;
  color: #bfbfbf;
}

.demo-tab.active {
  border-bottom: 2px solid #bfbfbf;
}

.demo-tab:hover {
  cursor: pointer;
  color: #fff;
}

.demo-button {
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  padding: .5rem;
  min-width: 150px;
  margin: 1rem 0;
}
.demo-button:hover {
  background-color: #bfbfbf;
  color: #131313;
}

.demo-picture {
  margin: 2.5rem 0rem;
}
.demo-picture img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}
.what-section {
  padding: 2rem 2rem;
  /* background-color: #131313; */
}
.what-section h1{
  font-weight: bolder;
  margin: 1rem 0;
  text-align: center;
}
.what-section p {
  color: #bfbfbf;
  text-align: center;
}
.what-card {
  padding: 1.5rem;
  margin: 2rem .5rem;
  border-radius: 5px;
  background-color: #222;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  min-height: 300px;  
}
.what-card h2{
  padding: 1rem 0;
  font-weight: bolder;
}
.what-card p{
  text-align: left;
}

.community-section h1{
  font-weight: bolder;
  margin: 1rem 0;
}
.community-section p {
  color: #bfbfbf;
  margin: 1rem 0;
}
.community-section div {
  margin: 2rem;
  padding: 4rem 2rem;
  background-color: #131313;
  text-align: center;
  border-radius: 10px;
}

.community-button {
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  padding: .25rem;
  min-width: 150px;
  margin: 1rem 0;
}
.community-button:hover {
  background-color: #bfbfbf;
  color: #131313;
}
.started-section {
  text-align: center;
  margin: auto;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/splashBackground.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 400px;
}
.started-section button {
  margin-bottom: 4rem;
}
footer {
  background-color: #131313;
  padding: 4rem 4rem;
}

footer a:hover {
  text-decoration: underline;
}

.footer-container {
  display: flex;
  width: 100%;
}
.footer-flex {
  display: flex;
  width: 50%;
  padding-bottom: 4rem;
}
.footer-flex div{
  margin-left: 6rem;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 50%;
}
.logo {
  background: url(../../assets/logoName.png) no-repeat;
  background-size: 149px 62px;
  background-position: 0 0;
}
.trademark {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #3B3B3B;
}
@media (max-width: 1500px) {
  .footer-flex div{
    margin-left: 0rem;
  }
  .splash-wrapper {
    background-position: 0px 0px;
    height: 100vh;
  }
  .slogan {
    font-size: 35px;
    width: auto;
    line-height: 50px;
  }
  .slogan-description {
    font-size: 15px;
  }
}
