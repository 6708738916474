.digital-brain-wrapper {
  background-image: url(../../assets/splashBackground.png);
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
  color: #f5f5f5;

  height: 100vh;
  padding: 70px 7%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.all-brains{
  display: flex;
  flex-direction: column;
  gap: 70px;
}

.digital-brain-nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-account-title {
  font-family: "Mulish", sans-serif;
  font-size: 50px;
  background: linear-gradient(183.35deg, #ffffff -50.82%, #563686 437.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: bold;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.current-brains-wrap {
  display: flex;
  gap: 25px;
  margin-top: 15px;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.current-brains-title {
  font-size: 18px;
  font-family: "Mulish", sans-serif;
  color: #bfbfbf;
  font-size: 22px;
}

.current-brains {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  padding: 20px 10px;
  min-width: 15rem;
  max-width: 15rem;
  height: 100px;
  border: 1px solid #bfbfbf;
  border-radius: 6px;
  transition: 0.3s;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}

.current-brains:hover {
  background-color: #563686;
  border: 1px solid #563686;
}

.delete {
  font-size: 14px;
  font-family: "Mulish", sans-serif;
  color: #bfbfbf;
  cursor: pointer;
  transition: 0.3s;
}

.delete:hover {
  color: #8a77bc;
}
