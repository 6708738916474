/* TODO Add site wide styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Mulish', sans-serif;
    background-color: #1C1C1C;
}



.mce-content-body {
    background: #1C1C1C
}

@media (max-width: 1023px) {
    .waitlist-left-content {
      margin-bottom: 5rem;
    }
  }


.tox-tinymce {
  height: 100% !important;
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1000;
}

  