.main-content {
  transition: transform 0.3s ease;
  position: absolute;
  width: 100%;
  height: 100vh;
  scrollbar-width: none;
}

.main-content::-webkit-scrollbar {
  display: none;
}


.main-content.shrink {
  width: 58%;
}

.chat-wrapper {
  display: flex;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  border-left: white;
}


.chat-wrapper::-webkit-scrollbar {
  display: none;
}

.sidebar-container {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
  transition: all 0.3s ease;
  z-index: 1;
  scrollbar-width: none;
  
}

.sidebar-container::-webkit-scrollbar {
  display: none;
}

.sidebar-container.open {
  width: 35%;
}

.sidebar {
  height: 100%;
  width: 100%;
  /* padding: 10px 14px; */
  background-color: #242526;
  color: #ccc;
  position: relative;
  display: flex;
  z-index: 1;
  display: hidden;
  scrollbar-width: none;
}

.toggle-button {
  position: absolute;
  bottom: 3rem;
  left: -1rem;
  z-index: 100;
  /* right: 5rem; */
  display: flex;
  color: #ccc;
  border: none;
  cursor: pointer;
  font-size: 1em;
  margin: 10px;
  transition: all 0.3s ease;
}

.sidebar header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
}

.sidebar .image-text {
  display: flex;
  align-items: center;
}

.sidebar .image {
  width: 50px;
  height: 50px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 1em;
}

.sidebar .text.logo-text .name {
  font-size: 1.2em;
  font-weight: bold;
}

.sidebar .text.logo-text .profession {
  font-size: 0.9em;
  color: #888;
}

.sidebar .toggle {
  font-size: 1.2em;
  cursor: pointer;
}

.sidebar .menu-bar {
  width: 100%;
  scrollbar-width: none;
}

.sidebar .menu {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 95%;
  padding-bottom: 8rem;
}

.sidebar .menu::-webkit-scrollbar {
  display: none;
}

.sidebar .menu-links {
  list-style-type: none;
  padding: 0;
}
